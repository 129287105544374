// facebook.tsx

export const FacebookIcon: React.FC = ({ className = 'fill-sky-300' }: { className?: string }) => (
  <svg
    className={`w-[1em] h-[1em] ${className}`}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 12.168C22.5 6.36953 17.7984 1.66797 12 1.66797C6.20156 1.66797 1.5 6.36953 1.5 12.168C1.5 17.4086 5.33906 21.7525 10.3594 22.5409V15.2041H7.69266V12.168H10.3594V9.85469C10.3594 7.22359 11.9273 5.76906 14.3255 5.76906C15.4744 5.76906 16.6763 5.97438 16.6763 5.97438V8.55859H15.3516C14.048 8.55859 13.6402 9.36766 13.6402 10.1992V12.168H16.552L16.087 15.2041H13.6406V22.5419C18.6609 21.7539 22.5 17.41 22.5 12.168Z"
    />
  </svg>
);
