const HamburgerIcon = ({ className = 'stroke-sky-200' }: { className?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-[1em] h-[1em] ${className}`}>
    <path d="M3 12.339H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 6.33898H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 18.339H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default HamburgerIcon;
