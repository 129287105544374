const PhoneIcon = ({ className = 'fill-white' }: { className?: string }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    className={`w-[1em] h-[1em] ${className}`}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_864_755)">
      <path d="M11.2251 8.67516C11.1028 8.54485 10.6708 8.15953 9.87678 7.64977C9.07686 7.13555 8.48694 6.81492 8.31772 6.74016C8.3028 6.73354 8.28637 6.73112 8.27018 6.73314C8.254 6.73516 8.23867 6.74156 8.22584 6.75164C7.95326 6.96422 7.49436 7.35469 7.46975 7.37578C7.31084 7.51195 7.31084 7.51195 7.18076 7.46953C6.95201 7.39453 6.24139 7.01719 5.62217 6.39656C5.00295 5.77594 4.60615 5.04774 4.53115 4.81922C4.48826 4.68891 4.48826 4.68891 4.6249 4.53C4.646 4.50539 5.0367 4.04649 5.24928 3.77414C5.25936 3.76132 5.26576 3.74599 5.26778 3.7298C5.2698 3.71361 5.26738 3.69718 5.26076 3.68227C5.186 3.51281 4.86537 2.92313 4.35115 2.1232C3.84069 1.32938 3.45584 0.897423 3.32553 0.775079C3.31357 0.763795 3.29879 0.755945 3.28274 0.752356C3.2667 0.748768 3.24998 0.749574 3.23436 0.754689C2.77884 0.911224 2.33934 1.11096 1.92186 1.35117C1.51883 1.58546 1.13727 1.85487 0.781623 2.15625C0.769208 2.16681 0.7599 2.18054 0.754698 2.19598C0.749495 2.21143 0.748595 2.22799 0.752092 2.24391C0.801076 2.47219 1.03522 3.42516 1.76178 4.74516C2.50311 6.09235 3.01686 6.78258 4.10553 7.8675C5.1942 8.95242 5.90623 9.49711 7.25483 10.2384C8.57483 10.965 9.52826 11.1994 9.75607 11.2479C9.77203 11.2514 9.78862 11.2504 9.80409 11.2452C9.81956 11.24 9.83334 11.2308 9.84397 11.2184C10.1453 10.8627 10.4146 10.4812 10.6488 10.0781C10.889 9.66062 11.0887 9.22112 11.2453 8.76563C11.2503 8.75012 11.2511 8.73355 11.2475 8.71765C11.244 8.70174 11.2363 8.68707 11.2251 8.67516Z" />
    </g>
    <defs>
      <clipPath id="clip0_864_755">
        <rect width="12" height="12" />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneIcon;
