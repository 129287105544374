import { cva, type VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import { RightArrow } from '../Icons';

export type ButtonVariantProps = VariantProps<typeof ButtonStyles> &
  React.ComponentPropsWithRef<'a'> & {
    arrow?: boolean;
    element?: React.ElementType;
    // href?: string | null | undefined;
  };

// Define base styles and variants
const ButtonStyles = cva(
  'rounded-full inline-flex items-center font-medium group leading-none transition-colors', // Base styles
  {
    variants: {
      color: {
        darkBlue: 'bg-blue-500 text-white hover:border-white border border-blue-500 stroke-white',
        blue: 'bg-sky-300 text-green-600 stroke-green-600',
        lightBlue:
          'bg-sky-200 text-green-600 hover:bg-sky-300 stroke-green-600 group-hover:bg-sky-300',
        skyBlue:
          'bg-sky-100 text-green-600 hover:bg-green-600 stroke-green-600 group-hover:bg-green-600 hover:text-white group-hover:text-white',
        darkGreen: 'bg-green-600 text-sky-200 hover:bg-green-700 stroke-sky-200',
        white: 'bg-white text-green-600 hover:bg-sky-200 stroke-green-600',
        whiteOutlined:
          'bg-white text-green-600 hover:bg-sky-200 stroke-green-600 border border-green-600',
        greenOutline: '  stroke-green-600 border border-green-600 text-green-600',
        whiteOutline: 'text-white  stroke-white border border-white',
        lightBlueOutline: 'text-sky-200  stroke-sky-200 border border-sky-200',
      },
      size: {
        xsmall: 'h-6 px-2 text-xs',
        small: 'h-8 px-3 text-sm',
        medium: 'h-12 px-5 text-base',
        large: 'h-14 px-8 text-lg',
        circle: 'h-12 w-12 px-0 justify-center',
        circleSmall: 'h-8 w-8 px-0 justify-center text-sm',
      },
    },
    defaultVariants: {
      color: 'lightBlue', // Default size if none is provided
      size: 'medium',
    },
  },
);

const Button = ({
  children,
  color,
  size,
  href,
  arrow = false,
  element = Link,
  ...props
}: ButtonVariantProps) => {
  const El = element;
  return (
    <El href={href || '#'} {...props} className={ButtonStyles({ color, size })}>
      {children}
      {arrow && (
        <RightArrow className="ml-2 translate-x-0 transition-transform group-hover:translate-x-[2px]" />
      )}
    </El>
  );
};

export default Button;
