import { cva, type VariantProps } from 'class-variance-authority';

export type ButtonVariantProps = VariantProps<typeof navButtonStyles> & {
  href: string | null | undefined;
  children: React.ReactNode;
};

// Define base styles and variants
const navButtonStyles = cva(
  'rounded-full h-8 px-3 inline-flex items-center font-medium text-sm', // Base styles
  {
    variants: {
      color: {
        darkBlue: 'bg-blue-500 text-white hover:border-white border border-blue-500',
        blue: 'bg-sky-300 text-green-600',
        lightBlue: 'bg-sky-200 text-green-600 hover:bg-sky-200',
        darkGreen: 'bg-green-600 text-white hover:bg-green-700',
      },
    },
    defaultVariants: {
      color: 'lightBlue', // Default size if none is provided
    },
  }
);

const NavButton = ({ children, color, href, ...props }: ButtonVariantProps) => {
  return (
    <a href={href || '#'} {...props} className={navButtonStyles({ color })}>
      {children}
    </a>
  );
};

export default NavButton;
