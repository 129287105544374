export const CarIcon = ({ className = 'fill-green-600' }: { className?: string }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-[1.25em] w-auto ${className}`}>
    <path d="M21.0564 10.3491C21.0457 10.2995 21.03 10.2511 21.0095 10.2047L19.2386 6.07219C18.8303 5.11734 17.837 4.5 16.7069 4.5H7.43595C6.30673 4.5 5.31251 5.11734 4.9047 6.07219L3.13189 10.2047C3.0916 10.2979 3.07101 10.3984 3.07142 10.5V18.75C3.07142 18.9489 3.15043 19.1397 3.29109 19.2803C3.43174 19.421 3.6225 19.5 3.82142 19.5H5.32142C5.52033 19.5 5.71109 19.421 5.85175 19.2803C5.9924 19.1397 6.07142 18.9489 6.07142 18.75V18H18.0714V18.75C18.0714 18.9489 18.1504 19.1397 18.2911 19.2803C18.4317 19.421 18.6225 19.5 18.8214 19.5H20.3214C20.5203 19.5 20.7111 19.421 20.8517 19.2803C20.9924 19.1397 21.0714 18.9489 21.0714 18.75V10.5C21.0715 10.4493 21.0665 10.3987 21.0564 10.3491ZM6.82142 15C6.52475 15 6.23474 14.912 5.98806 14.7472C5.74139 14.5824 5.54913 14.3481 5.4356 14.074C5.32207 13.7999 5.29236 13.4983 5.35024 13.2074C5.40812 12.9164 5.55098 12.6491 5.76076 12.4393C5.97054 12.2296 6.23781 12.0867 6.52878 12.0288C6.81975 11.9709 7.12135 12.0006 7.39544 12.1142C7.66953 12.2277 7.9038 12.42 8.06862 12.6666C8.23344 12.9133 8.32142 13.2033 8.32142 13.5C8.32142 13.8978 8.16338 14.2794 7.88208 14.5607C7.60077 14.842 7.21924 15 6.82142 15ZM17.3214 15C17.0247 15 16.7347 14.912 16.4881 14.7472C16.2414 14.5824 16.0491 14.3481 15.9356 14.074C15.8221 13.7999 15.7924 13.4983 15.8502 13.2074C15.9081 12.9164 16.051 12.6491 16.2608 12.4393C16.4705 12.2296 16.7378 12.0867 17.0288 12.0288C17.3198 11.9709 17.6214 12.0006 17.8954 12.1142C18.1695 12.2277 18.4038 12.42 18.5686 12.6666C18.7334 12.9133 18.8214 13.2033 18.8214 13.5C18.8214 13.8978 18.6634 14.2794 18.3821 14.5607C18.1008 14.842 17.7192 15 17.3214 15ZM4.9586 9.75L6.28189 6.66328C6.45157 6.26672 6.91517 6 7.43595 6H16.7069C17.2272 6 17.6913 6.26672 17.8609 6.66328L19.1842 9.75H4.9586Z" />
  </svg>
);
