'use client';

import UpArrowIcon from '../Icons/UpArrowIcon';

const ScrollToTopButton = () => {
  const scrollToTop = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <button
      onClick={scrollToTop}
      className="rounded-full bg-sky-300 w-12 h-12 flex items-center justify-center">
      <UpArrowIcon />
    </button>
  );
};

export default ScrollToTopButton;
