// linkedin.tsx

export const LinkedInIcon: React.FC = ({ className = 'fill-sky-300' }: { className?: string }) => (
  <svg
    className={`w-[1em] h-[1em] ${className}`}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20.8205 1.60449H3.29438C2.33672 1.60449 1.5 2.29355 1.5 3.23996V20.805C1.5 21.7565 2.33672 22.6045 3.29438 22.6045H20.8153C21.7781 22.6045 22.5 21.7509 22.5 20.805V3.23996C22.5056 2.29355 21.7781 1.60449 20.8205 1.60449ZM8.00953 19.109H5.00109V9.75512H8.00953V19.109ZM6.60938 8.33293H6.58781C5.625 8.33293 5.00156 7.61621 5.00156 6.71902C5.00156 5.80543 5.64141 5.10559 6.62578 5.10559C7.61016 5.10559 8.2125 5.80027 8.23406 6.71902C8.23359 7.61621 7.61016 8.33293 6.60938 8.33293ZM19.0045 19.109H15.9961V13.9945C15.9961 12.7692 15.5583 11.932 14.4698 11.932C13.6383 11.932 13.1461 12.4945 12.9272 13.0425C12.8452 13.2393 12.8231 13.5075 12.8231 13.7812V19.109H9.81469V9.75512H12.8231V11.0568C13.2609 10.4334 13.9448 9.53621 15.5363 9.53621C17.5111 9.53621 19.005 10.8379 19.005 13.6443L19.0045 19.109Z" />
  </svg>
);
