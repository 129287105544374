const UpArrowIcon = ({ className = 'stroke-green-600' }: { className?: string }) => (
  <svg
    className={`w-[1em] h-[1em] ${className}`}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 9.27649L12 2.52649L18.75 9.27649M12 3.46399L12 22.339"
      stroke="#063131"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UpArrowIcon;
